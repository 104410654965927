import { Carousel } from "react-responsive-carousel"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import './portfolio.css'
import React from "react"

// Styles
import "./portfolio.css"

// components



import { faExternalLinkSquareAlt } from "@fortawesome/free-solid-svg-icons/faExternalLinkSquareAlt"
// import { faGithubSquare } from "@fortawesome/free-brands-svg-icons/faGithubSquare"
import { faUserLock } from "@fortawesome/free-solid-svg-icons/faUserLock"



const PortoflioMobile = ({data})=> {
    return(
        <div className="row">
        <div>
        <Carousel
          showArrows={false}
          showIndicators={false}
          showThumbs={true}
          showStatus={false}
          infiniteLoop={true}
          autoPlay={true}
          interval={2000} // Adjust the autoplay interval as needed
          width={"100%"}
        >
          {data.length &&
            data.map((item, index) => (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div className="column" key={index}>
                  {/* {remove asset.url} */}
                  <img src={item?.img} alt={item.title} />
                  <div className="overlay">
                    <div className="left">
                      <h3>{item.title}</h3>
                      {item.tagline && <p>{item.tagline}</p>}
                    </div>
                    <div className="right">
                      {item.liveUrl !== "private" ? (
                        <a
                          href={item.liveUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FontAwesomeIcon
                            icon={faExternalLinkSquareAlt}
                            size="2x"
                            className="icon"
                            title="Live view"
                          />{" "}
                        </a>
                      ) : (
                        <a href="#_" rel="noopener noreferrer">
                          <FontAwesomeIcon
                            icon={faUserLock}
                            size="2x"
                            className="icon"
                            title="Private Link"
                          />{" "}
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </Carousel>
        </div>
      </div>
    )
}

export default PortoflioMobile;