import React from "react";

// Styles
import "./services.css";

// Card
import { ServicesCard } from "components/Services/ServicesCard/ServicesCard";
import { Heading } from "components/Heading/Heading";
import services from "constants/services";
import { Carousel } from "react-responsive-carousel";
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const Services = () => {
  return (
    <section
      // data-aos="fade-right"
      // className="services-container "
      name="services"
      id="services"
    >
      <Heading text="SKILLS" />

      {/* <div className="centered-carousel"> */}
      {/* <div className="service-card-container"> */}
      <Carousel
      showIndicators={false}
      showArrows={false}
      showThumbs={true}
      showStatus={false}
      infiniteLoop={true}
      width={"100%"}
      autoPlay={true}
      interval={2000} // Adjust the autoplay interval as needed
      centerMode={true} // Enable center mode
      centerSlidePercentage={100}
      >
          {services &&
            services?.map(({ title, icon, tags }, index) => (
              <div style={{width:"100%",padding:"20px 0px"}}>
                <ServicesCard key={index} image={icon} title={title} tags={tags} />
              </div>
            ))}
      </Carousel>
          {/* </div> */}
      {/* </div> */}
    </section>
  );
};

export default Services;
