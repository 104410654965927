import React from "react";
// import { AiFillPhone } from "react-icons/ai";
// import { FaLinkedinIn } from "react-icons/fa";
// import { RxDownload } from "react-icons/rx";
// import { BsGithub } from "react-icons/bs";
import "./footer.css";
const Footer = () => {
  return (
    <div className="footer-container">
      {/* <div className="social-section">
        <a href="tel:+13152340375" rel="noreferrer">
          <AiFillPhone size={40} className="xyz mb_show" />
        </a>
        <a href="https://web.whatsapp.com/" target="_blank" rel="noreferrer">
          <AiFillPhone size={40} className="xyz mb_none" />
        </a>

        <a
          href="https://www.linkedin.com/in/saif-khan-07a88b84/"
          target="_blank"
          rel="noreferrer"
        >
          <FaLinkedinIn size={40} className="xyz" />
        </a>
        <a href="https://github.com/">
          <BsGithub size={40} className="xyz" />
        </a>

        <a
          href="https://drive.google.com/uc?export=download&id=1Y8SUEPxFEjpE28sC-Jn-CQrYWmzn0DKr"
          rel="noreferrer"
        >
          <RxDownload size={40} className="xyz" />
        </a>
      </div> */}
      <div className="copywrite-section">
        <p>
          © 2023. Designed by Saif Khan{" "}
          <span role="img" aria-label="Coffee">
            ☕
          </span>{" "}
          . All right reserved.
        </p>
      </div>
    </div>
  );
};
export default Footer;
