// import pernStackLogo from '../images/PERN.png';
// import mernStackLogo from '../images/mernstack.png';
import frontendlogo from '../images/frontt-end.avif';
import backendLogo from '../images/Back-End.png';
import fullstacklogo from '../images/Full-Stack.png';

const services = [
    {
        icon:frontendlogo,
        title:'Front-End Development',
        tags:['JavaScript','CSS','HTML','React','Vue.js','Angular','Bootstrap','Material UI','Tailwind CSS','Next.js']

    },
    // {
    //     icon:pernStackLogo,
    //     title:'PERN Stack Development',
    //     tags:['PostgreSQL','Express.js','React.js','Node.js']
    
    // },
    // {
    //     icon:mernStackLogo,
    //     title:'MERN Stack Development',
    //     tags:['MongoDB','Express.js','React','Node.js']

    // },
    {
        icon:backendLogo,
        title:'Back-End Development',
        tags:['Node.js','Nest.js','SQL','NoSQL databases','Microservices architecture','(CI/CD)','JavaScript',' Express.js','Spring Boot','AJAX','GraphQL']

    },
    {
        
        icon:fullstacklogo,
        title:'Full Stack Development',
        tags:['HTML5/CSS3','Nest.js','JavaScript','React.js','Vue.js','Angular','Redux','Bootstrap / Material-UI','AJAX.js','GraphQL','Node.js','Express.js','Spring Boot','MySQL','PostgreSQL','NoSQL','ORM/ODM','Postman']
    
    }
]

export default services