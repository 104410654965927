import React, { useState, useRef } from "react";

// Styles
import "./contact.css";

// Email Js
import emailjs from "emailjs-com";

// image
import shakeImage from "../../images/shake.svg";

// Components
import { Heading } from "../Heading/Heading";
import Button from "components/Button/Button";

const Contact = () => {
  const INITIAL_VALUES = { email: "", subject: "", message: "" };
  const [notification, setNotification] = useState(false);
  const [values, setValues] = useState(INITIAL_VALUES);
  const [loading, setLoading] = useState(false);

  // Email-js
  const form = useRef();

  const sendEmail = async () => {
    console.log("User's Email:", values.email);
    try {
      const result = await emailjs.sendForm(
        `service_356gmgn`,
        `template_kxw0hcl`,
        form.current,
        `qRiZzpumnzGWPwJ23`
      );
      setTimeout(()=>{
        setNotification(false)
      }, 2200)
      console.log("result ", result.text);
    } catch (error) {
      console.log("ERROR", error);
    }
  };
  // Email-js

  const clearForm = () => {
    setValues(INITIAL_VALUES);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // console.log("form submitted !", values);
    // console.log("form submitted !", values);
    // TODO : Send data to your email
    setLoading(true);
    await sendEmail();
    setLoading(false);
    clearForm();
    setNotification(true);
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <section className="contact" name="contact" id="contact">
      <Heading text="CONTACT" style={{ marginBottom: "3rem" }}  />
      <div className="content">
        <div className="left">
          <img src={shakeImage} alt="shake hands" />
        </div>
        <div className="right">
          <form onSubmit={handleSubmit} ref={form}>
            <input  
              type="email"
              name="email"
              value={values.email}
              onChange={handleChange}
              placeholder="Email"
              required
            />
            <input type="subject"
              name="subject"
              value={values.subject}
              onChange={handleChange}
              placeholder="Subject"
              required
            />
            <textarea
              name="message"
              required
              value={values.message}
              onChange={handleChange}
              placeholder="Message"
            ></textarea>
            <Button
              text={loading ? "Sending..." : "Send"}
              loading={loading}
              type="submit"
            />
            {/* {notification && (
              <span style={{ color: ("#3d5a81"), fontWeight: "bold", marginTop: "1rem" }}>
                Thanks, I will reply ASAP :)
              </span>
            )} */}
            {notification && (
              <span
                className="notification"
              >
                Thanks, I will reply ASAP :)
              </span>
            )}
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
