import zayacare from '../../src/images/zayacare.png'
import cantactix from '../../src/images/cantactix.png'
import thingPilot from '../../src/images/thingPilot.png'
import esitech from '../../src/images/esitech.png'
import catalyst from '../../src/images/catalyst.png'
import LyveCom from '../../src/images/LyveCom.png'


const projects = [
    {
        category: 'Projects',
        title: 'ZayaCare',
        img: zayacare,
        tagline: 'Next JS, React JS, Elastic Ui',
        liveUrl: 'https://zayacare.com/',
    },
    {
        category: 'Projects',
        title: 'Cantactix',
        img: cantactix,
        tagline: 'React JS, Material Ui, Redux',
        liveUrl: 'http://cantactix.com/',
    },
    {
        category: 'Projects',
        title: 'Catalyst',
        img: catalyst,
        tagline: 'Angular JS, Vue JS',
        liveUrl: 'https://catalystux.com/',
    },
    {
        category: 'Projects',
        title: 'EsiTech',
        img: esitech,
        tagline: 'React JS, Node JS, Express JS, MongoDB, Kubernetes',
        liveUrl: 'https://esi-tec.com/',
    },
    {
        category: 'Projects',
        title: 'LyveCom',
        img: LyveCom,
        tagline: 'React JS, i18Next, Semantic Ui, Django',
        liveUrl: 'https://lyvecom.com/',
    },
    {
        category: 'Projects',
        title: 'ThingPilot',
        img: thingPilot,
        tagline: 'Vue JS, Vuetify, Vuex, Vue Router',
        liveUrl: 'https://thingpilot.com/',
    },

    
]

export default projects;