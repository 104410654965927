import React, { useEffect, useState } from "react"

// Styles
import "./portfolio.css"

// components
import { Heading } from "components/Heading/Heading"



import PortfolioItem from "./PortfolioItem/PortfolioItem"
import projects from "../../../src/constants/portfolio";
import PortoflioMobile from "./PortfolioMobile"
import PortfolioWeb from "./PortfolioWeb"

const Portfolio = () => {
  const [selected, setSelected] = useState("Projects")
  const [data, setData] = useState([])
  const [projectList, setProjectList] = useState([])

  const isMobile = window.innerWidth < 768;
  useEffect(() => {
    let tempList = []
    projects.map(({ category }) => {
      if (!tempList.includes(category)) {
        tempList.push(category)
      }

      return null
    })
    setProjectList(tempList)
  }, [])

  useEffect(() => {
    projectList.map((list) => {
      if (selected === list) {
        setData(projects.filter((project) => project.category === list))
      }

      return null
    })
  }, [selected, projectList])

  return (
    <section
      data-aos="fade-left"
      className="portfolio"
      name="portfolio"
      id="portfolio"
    >
      <Heading text="PORTFOLIO" style={{ padding: "3rem" }} />
      <div className="list">
        {projectList &&
          projectList.map((list) => (
            <PortfolioItem
              title={list}
              key={list}
              active={selected === list}
              setSelected={setSelected}
              id={list}
            />
          ))}
      </div>
      {
        isMobile ? <PortoflioMobile data={data} /> : <PortfolioWeb data={data}/>
      }
    </section>
  )
}

export default Portfolio